import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Container, MenuItem, Select } from '@mui/material';
import { masters, getMasterByName } from './master-data-helper';

import AppDataTable from '../../common/components/AppDataTable';
import MasterMaintenancePrimaryArea from './components/MasterMaintenancePrimaryArea';
import MasterDownload from './components/MasterDownload';
import useAppContext from '../../common/hooks/useAppContext';
import AddUserDialog from '../../common/components/AddUserDialog';
import UpdateUserDialog from '../../common/components/UpdateUserDialog';
import DeleteUserDialog from '../../common/components/DeleteUserDialog';

const useStyles = makeStyles(theme => ({
  root: {
  },
  userContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: 8
  },
  manageMenu: {
    marginLeft: 50,
    border: "2px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    width: 300,
    height: 260
  },
  menuItem: {
    width: "100%",
    marginBottom: 16,
    display: "flex",
    justifyContent: "center",
  }
}));

const MasterDownloadPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const [name, setName] = useState('Corporate');
  const [master, setMaster] = useState(getMasterByName('Corporate'));
  const [tableFields, setTableFields] = useState([]);
  const [data, setData] = useState([]);
  const [selectList, setSelectList] = useState({
    role: [
      {label: "Master", value: "Master"},
      {label: "PlatformAdmin", value: "PlatformAdmin"},
      {label: "ConcreteMaker", value: "ConcreteMaker"},
      {label: "ConstructorAdmin", value: "ConstructorAdmin"},
      {label: "ConstructorUser", value: "ConstructorUser"},
      {label: "DataEntry", value: "DataEntry"}
    ]
  });

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showUpdateUserDialog, setShowUpdateUserDialog] = useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleExecute = async () => {
    try {
      app.setIsLoading(true);
      const req = {
        name: name,
      };
      const res = await app.http().get('/master/maintenance', req);
      const masterDef = getMasterByName(name);
      const fieldsDef = masterDef.itemLabels.map((label, i) => ({
        label: label,
        name: masterDef.itemNames[i],
        type: 'text'
      }));
      setMaster(masterDef);
      setTableFields(fieldsDef);
      setData(res);

      if (name === "User") {
        const resCorporate = await app.http().get('/master/maintenance', {name: "Corporate"});
        selectList.loginId = res.map(e => {
          return {label: e.loginId, value: e.loginId, data: e}
        });
        selectList.corporateId = resCorporate.filter(e => e.active === 1).map(e => {
          return {label: e.corporateName, value: e._id}
        });
        setSelectList({...selectList})
      }
    } catch (err) {
      app.handleHttpError(err);
    } finally {
      app.setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <MasterMaintenancePrimaryArea selected="master-download">
        <Container component="main" maxWidth="md" sx={{ py: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <Select
              size="small"
              name="master"
              onChange={handleChange}
              value={name}
              sx={{ mr: 2, width: 240 }}
            >
              {masters.map((s, i) => (
                <MenuItem key={i} value={s.name}>
                  <span className={classes.selectText}>{s.label}</span>
                </MenuItem>
              ))}
            </Select>
            <Button color="info" variant="contained" onClick={handleExecute}>マスタデータ取得</Button>
          </Box>
        </Container>
      </MasterMaintenancePrimaryArea>
      <div style={{display: "flex"}}>
        <Container component="main" maxWidth="md" sx={{ py: 1 }}>
          <Box sx={{ float: 'left' }}>
            {data.length > 0 && <p>データ件数: {data.length.toLocaleString()} 件</p>}
          </Box>
          <Box sx={{ my: 1, float: 'right' }}>
            <MasterDownload master={master} data={data} />
          </Box>
          <AppDataTable groupFields={[]} tableFields={tableFields} data={data} maxHeight="calc(100vh - 400px)" />
        </Container>
        {
          tableFields.length > 0 && name === "User" && (
            <div className={classes.userContainer}>
              <div className={classes.manageMenu}>
                <div className={classes.menuItem} style={{fontSize: 20, fontWeight: 700, marginTop: 32}}>ユーザー管理</div>
                <div className={classes.menuItem}><Button color="info" variant="contained" onClick={() => setShowAddUserDialog(true)}>ユーザー追加</Button></div>
                <div className={classes.menuItem}><Button color="info" variant="contained" onClick={() => setShowUpdateUserDialog(true)}>ユーザー編集</Button></div>
                <div className={classes.menuItem}><Button color="info" variant="contained" onClick={() => setShowDeleteUserDialog(true)}>ユーザー削除</Button></div>
              </div>
            </div>
          )
        }
      </div>
      <AddUserDialog open={showAddUserDialog} onClose={() => setShowAddUserDialog(false)} selectList={selectList} />
      <UpdateUserDialog open={showUpdateUserDialog} onClose={() => setShowUpdateUserDialog(false)} selectList={selectList} />
      <DeleteUserDialog open={showDeleteUserDialog} onClose={() => setShowDeleteUserDialog(false)} selectList={selectList} />
    </div>
  );
};

export default MasterDownloadPage;
