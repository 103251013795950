import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle } from '@mui/material';

import useAppContext from '../hooks/useAppContext';
import AppForm from './AppForm';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 700
  }
}));

const loginFormFields = [
  { name: "loginId", label: "ログインID", type: "select", required: true }
];
const formFields = [
  { name: "loginId", label: "ログインID", type: "select", required: true },
  { name: "password", label: "パスワード", type: "password", readonly: true },
  { name: "displayName", label: "ログイン表示名", type: "text", readonly: true },
  { name: "corporateId", label: "企業ID", type: "select", readonly: true },
  { name: "role", label: "ロール", type: "select", readonly: true }
];

const DeleteUserDialog = ({ open, onClose, selectList }) => {
  const classes = useStyles();
  const app = useAppContext();
  const [loginData, setLoginData] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (loginData && loginData.loginId) {
      const selected = selectList.loginId.filter(e => e.value === loginData.loginId)[0];
      setData(selected?.data);
    }
  }, [loginData]);

  const handlers = {
    loginId: {
      onChange: (event, values) => {
        const selected = selectList.loginId.filter(e => e.value === event.target.value)[0];
        setData(selected?.data);
      }
    }
  };

  const handleDelete = async (values) => {
    app.showConfirmDialog('', '本当にこのユーザーを削除しますか？', async (result) => {
      if (result) {
        try {
          app.setIsLoading(true);
          await app.http().delete(`/master/user/${values.loginId}`);
          app.showMessageDialog('', 'ユーザーを削除しました', null, 'OK');
        } catch (err) {
          app.handleHttpError(err);
        } finally {
          app.setIsLoading(false);
        }
      }
    }, {color: "error", text: '削除'}, {text: 'キャンセル'});
  };

  const close = () => {
    onClose();
    setLoginData(null);
    setData(null);
  };

  const submitActions = [
    { name: '削除', handler: handleDelete, color: 'error' },
    { name: '戻る', handler: close, variant: 'outlined', skipValidation: true, color: 'primary' }
  ];

  return (
    <Dialog open={open} onClose={close} classes={{ paper: classes.paper }}>
      <DialogTitle>ユーザー削除</DialogTitle>
      <DialogContent>
        {loginData && loginData.loginId ? 
          <AppForm formFields={formFields} data={data} submitActions={submitActions} selectList={selectList} handlers={handlers} columns={{ xs: 1, md: 1 }} />
        : <AppForm formFields={loginFormFields} selectList={selectList} watch={setLoginData} columns={{ xs: 1, md: 1 }} />}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteUserDialog;
