import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Toolbar } from '@mui/material';

import AppHeader from '../common/components/AppHeader';
import AppDrawer from '../common/components/AppDrawer';
import ConstructorAdminActivityListPage from '../constructor-admin/activity/ActivityListPage';
import ConstructorAdminDeliveryListPage from '../constructor-admin/activity/DeliveryListPage';
import ConstructorAdminViewDeliveryPage from '../constructor-admin/activity/ViewDeliveryPage';
import JCreditReportPage from '../common/pages/jcredit-report/JCreditReportPage';
import JCreditReportViewPage from '../common/pages/jcredit-report/JCreditReportViewPage';
import CO2ReportPage from '../common/pages/co2-report/CO2ReportPage';
import CO2ReportViewPage from '../common/pages/co2-report/CO2ReportViewPage';
import MasterDownloadPage from '../platform-admin/data-maintenance/MasterDownloadPage';
import MasterMaintenancePage from '../platform-admin/data-maintenance/MasterMaintenancePage';
import FileMaintenancePage from '../platform-admin/data-maintenance/FileMaintenancePage';
import BlockchainMaintenancePage from '../platform-admin/data-maintenance/BlockchainMaintenancePage';
import SlipDataRegistrationPage from '../platform-admin/data-maintenance/SlipDataRegistrationPage';
import ConcretePlantConstructListPage from '../concrete-plant/delivery/ConstructListPage';
import ConcretePlantDeliveryListPage from '../concrete-plant/delivery/DeliveryListPage';
import EditDeliveryPage from '../concrete-plant/delivery/EditDeliveryPage';
import ConcretePlantViewDeliveryPage from '../concrete-plant/delivery/ViewDeliveryPage';
import DeliveryGroupListPage from '../concrete-plant/delivery/DeliveryGroupListPage';
import EditPrecastConcretePage from '../concrete-plant/delivery/EditPrecastConcretePage';

import DirectActivityListPage from '../common/pages/co2-report/DirectActivityListPage';
import DirectActivityPage from '../common/pages/co2-report/DirectActivityPage';

import ConstructorUserConstructListPage from '../constructor-user/construct/ConstructListPage';
import ConstructPage from '../constructor-user/construct/ConstructPage';
import CompoundPlanListPage from '../constructor-user/construct/CompoundPlanListPage';
import CompoundPlanPage from '../constructor-user/construct/CompoundPlanPage';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
  },
}));

const pages = [
  { name: 'データ管理', path: '/master/data-maintenance' },
  { name: '施工会社・現場名一覧', path: '/master/construct' },
  { name: '現場名・活動量一覧', path: '/master/activity' },
  { name: 'J-クレジット申請レポート', path: '/master/jcredit-report' },
  { name: 'CO2排出レポート', path: '/master/co2-report' },
  { name: '現場管理', path: '/master/manage-construct' },
];

const MasterPage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppHeader />
      <AppDrawer pages={pages}></AppDrawer>
      <div className={classes.content}>
        <Toolbar variant="dense" />
        <Routes>
          <Route path="/" element={<Navigate to="./data-maintenance" />} />
          <Route path="/activity" element={<ConstructorAdminActivityListPage />} />
          <Route path="/activity/:activityId/delivery" element={<ConstructorAdminDeliveryListPage />} />
          <Route path="/activity/:activityId/delivery/:deliveryId" element={<ConstructorAdminViewDeliveryPage />} />
          <Route path="/data-maintenance" element={<Navigate to="./master-download" />} />
          <Route path="/data-maintenance/master-download" element={<MasterDownloadPage />} />
          <Route path="/data-maintenance/master-maintenance" element={<MasterMaintenancePage />} />
          <Route path="/data-maintenance/file-maintenance" element={<FileMaintenancePage />} />
          <Route path="/data-maintenance/blockchain-maintenance" element={<BlockchainMaintenancePage />} />
          <Route path="/data-maintenance/slip-data-registration" element={<SlipDataRegistrationPage />} />
          <Route path="/construct" element={<ConcretePlantConstructListPage />} />
          <Route path="/construct/:constructId/delivery" element={<DeliveryGroupListPage />} />
          <Route path="/construct/:constructId/delivery-all" element={<ConcretePlantDeliveryListPage />} />
          <Route path="/construct/:constructId/delivery/:deliveryId/edit" element={<EditDeliveryPage />} />
          <Route path="/construct/:constructId/delivery/:deliveryId/view" element={<ConcretePlantViewDeliveryPage />} />
          <Route path="/construct/:constructId/delivery/precastConcrete" element={<EditPrecastConcretePage />} />
          <Route path="/activity" element={<ConstructorAdminActivityListPage />} />
          <Route path="/activity/:activityId/delivery" element={<ConstructorAdminDeliveryListPage />} />
          <Route path="/activity/:activityId/delivery/:deliveryId" element={<ConstructorAdminViewDeliveryPage />} />
          <Route path="/jcredit-report" element={<JCreditReportPage />} />
          <Route path="/jcredit-report/view" element={<JCreditReportViewPage />} />
          <Route path="/co2-report" element={<CO2ReportPage />} />
          <Route path="/co2-report/view" element={<CO2ReportViewPage />} />
          <Route path="/co2-report/view/direct-activity" element={<DirectActivityListPage />} />
          <Route path="/co2-report/view/direct-activity/:activityId" element={<DirectActivityPage />} />
          <Route path="/manage-construct" element={<ConstructorUserConstructListPage />} />
          <Route path="/manage-construct/:constructId" element={<ConstructPage />} />
          <Route path="/manage-construct/:constructId/compound-plan" element={<CompoundPlanListPage />} />
          <Route path="/manage-construct/:constructId/compound-plan/:compoundPlanId" element={<CompoundPlanPage />} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default MasterPage;
