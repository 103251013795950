import React from 'react';
import { makeStyles } from '@mui/styles';
import { DialogContent, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: 350,
    padding: 24
  },
  buttonArea: {
    justifyContent: 'center'
  },
  button: {
    minWidth: 80
  }
}));

const ConfirmDialog = props => {
  const { open, title, body, onClose, leftFoot, rightFoot } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </DialogContent>
      <DialogActions sx={{justifyContent: "flex-start", padding: "0 24px 16px"}}>
        <Button
          className={classes.button}
          onClick={() => onClose(true)}
          color={leftFoot ? leftFoot.color : "info"}
          variant="contained"
          autoFocus
        >
          {leftFoot ? leftFoot.text : "はい"}
        </Button>
        <Button
          className={classes.button}
          onClick={() => onClose(false)}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {rightFoot ? rightFoot.text : "いいえ"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
