import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AppPrimaryArea from '../../components/AppPrimaryArea';
import AppAccordion from '../../components/AppAccordion'
import AppDataTable from '../../components/AppDataTable';
import JCreditReportBasicInfo from './components/JCreditReportBasicInfo';
import JCreditReportDownload from './components/JCreditReportDownload';
import useAppContext from '../../hooks/useAppContext';
import { routeMap, getFormatDecimal, getFormatPercent, concreteLocationToLabel, judementToLabel } from '../../data-helper';

const breadcrumbs = [
  { name: 'J-クレジット申請レポート', path: '../jcredit-report' },
];

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const JCreditReportViewPage = () => {
  const classes = useStyles();
  const app = useAppContext();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [groupFields, setGroupFields] = useState([]);
  const [tableFields, setTableFields] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const toDisplayData = (data) => {
    const activityFields = [
      { "name": "activityNumber", "label": "活動番号", type: "number" },
    ];
    const constructFields = [
      // { "name": "constructInfo._id", "label": "建築物のID", type: "text" },
      // { "name": "constructInfo.corporateName", "label": "施工主の会社名", type: "text" },
      { "name": "constructInfo.constructName", "label": "削減活動を実施する建築物の名称", type: "text", width: 220 },
      { "name": "constructInfo.postalCode", "label": "郵便番号", type: "text" },
      { "name": "constructInfo.prefecture", "label": "都道府県", type: "text" },
      { "name": "constructInfo.address", "label": "住所", type: "text", width: 220 },
      { "name": "constructInfo.contact", "label": "連絡先(電話)", type: "text", width: 100 },
      { "name": "constructInfo.particiationStatus", "label": "低炭素社会実行計画への参加状況", type: "text" },
      { "name": "constructInfo.savingEntityId", "label": "省エネ法特定事業者番号", type: "text" },
      { "name": "constructInfo.globalWarmingEntityCode", "label": "温対法特定排出者コード", type: "text" },
      { "name": "constructInfo.enterDate", "label": "入会申込日", type: "text" },
      { "name": "constructInfo.enterDate2YearsAgo", "label": "入会申込日(2年前)", type: "text" },
      { "name": "constructInfo.buildingConfirmationDate", "label": "建築確認済証交付日", type: "text" },
      { "name": "constructInfo.completeDate", "label": "完了検査済証交付日", type: "text" },
      { "name": "constructInfo.completeEvidenceName", "label": "完了検査等確認書類", type: "text" },
      { "name": "constructInfo.targetStartDate", "label": "認証対象開始日", type: "text" },
      { "name": "constructInfo.targetEndDate", "label": "認証対象終了日", type: "text" },
      { "name": "constructInfo.applicationStartDate", "label": "認証申請開始日", type: "text" },
      { "name": "constructInfo.applicationEndDate", "label": "認証申請終了日", type: "text" },
    ];
    
    const specFields = [
      { "name": "specNumber", "label": "仕様番号", type: "number" },
      { "name": "activityID", "label": "活動量", type: "link", format: (d) => `/${routeMap[app.loginInfo.user.role]}/activity/${d}/delivery`, formatExport: (d) => d },
    ];
    const concreteFields = [
      { "name": "concreteInfo.location", "label": "コンクリート打設箇所", type: "text", format: concreteLocationToLabel, formatExport: concreteLocationToLabel },
      { "name": "concreteInfo.supplier", "label": "コンクリート調達先", type: "text", width: 180 },
      { "name": "concreteInfo.certificateNumber", "label": "コンクリートJIS認証番号", type: "text" },
      { "name": "concreteInfo.volume", "label": "コンクリート打設体積(m\u00B3)", type: "number", format: getFormatDecimal(1) },
    ];
    // const totalMainEmissionFields = [
    //   { "name": "mainEmission.totalMainEmission", "label": "プロジェクト主要排出量", type: "number" },
    // ];
    // const totalTransportEmissionFields = [
    //   { "name": "mainEmission.totalTransportEmission", "label": "プロジェクト付随的排出量(運搬)", type: "number" },
    // ];
    const totalProjectEmissionFields = [
      { "name": "totalProjectEmission", "label": "プロジェクト排出量合計(tCO2)", type: "number", format: getFormatDecimal(3) },
    ];
    const totalBaselineEmissionFields = [
      { "name": "totalBaselineEmission", "label": "ベースライン排出量合計(tCO2)", type: "number", format: getFormatDecimal(3) },
    ];
    const totalReductionFields = [
      { "name": "totalReduction", "label": "排出削減量(tCO2)", type: "number", format: getFormatDecimal(3) },
    ];
    const otherInfoFields = [
      { "name": "otherInfo.judgement", "label": "判定", type: "text", format: judementToLabel, formatExport: judementToLabel },
      { "name": "otherInfo.projectConcreteUnitPrice", "label": "プロジェクトコンクリート単価(円/m\u00B3)", type: "number", format: getFormatDecimal(0) },
      { "name": "otherInfo.baselineConcreteUnitPrice", "label": "ベースラインコンクリート単価(円/m\u00B3)", type: "number", format: getFormatDecimal(0) },
      { "name": "otherInfo.grant", "label": "補助金額", type: "text" },
      { "name": "otherInfo.grantName", "label": "補助金名称", type: "text" },
      { "name": "otherInfo.grantGroup", "label": "補助金交付団体", type: "text" },
      { "name": "otherInfo.grantNumber", "label": "補助金番号", type: "text" },
      { "name": "otherInfo.grantPaper", "label": "補助金受給確認書類", type: "text" },
    ];
    const groupDictionary = {
      'constructInfo': { label: '活動の実施情報', canCollapse: false, initialCollapse: false },
      'concreteInfo': { label: 'コンクリート情報', canCollapse: false, initialCollapse: false },
      'project': { label: 'プロジェクト排出量', canCollapse: false, initialCollapse: false },
      'project.mainEmission': { label: 'プロジェクト主要排出量', canCollapse: false, initialCollapse: true },
      'project.transportEmission': { label: 'プロジェクト付随的排出量', canCollapse: false, initialCollapse: false },
      'baseline': { label: 'ベースライン排出量', canCollapse: false, initialCollapse: false },
      'baseline.baselineEmission': { label: 'ベースライン主要排出量', canCollapse: false, initialCollapse: false },
      'baseline.baselineTransportEmission': { label: 'ベースライン付随的排出量', canCollapse: false, initialCollapse: false },
      'otherInfo': { label: 'その他', canCollapse: false, initialCollapse: false },
    };
    
    const getLabel = (kind, emission, content) => {
      switch (kind) {
        case 'unit':
          return `単位${content}(kg/m\u00B3)`;
        case 'ratio':
          return `個体中の${content}(%)`;
        case 'usage':
          return `${content}使用量(t)`;
        case 'emission':
          return (emission === 'transportEmission' || emission === 'baselineTransportEmission') ? `${content}運搬排出量(tCO2)` : `${content}排出量(tCO2)`;
        case 'distance':
          return `${content}運搬距離(km)`;
        case 'volume':
          return `${content}打設体積(m\u00B3)`;
        default:
          return '(unknown)';
      }
    };
    
    const getFormat = (kind) => {
      switch (kind) {
        case 'unit':
          return getFormatDecimal(3);
        case 'ratio':
          return getFormatPercent(1);
        case 'usage':
          return getFormatDecimal(3);
        case 'emission':
          return getFormatDecimal(3);
        case 'distance':
          return getFormatDecimal(1);
        case 'volume':
          return getFormatDecimal(3);
        default:
          return null;
      }
    };
    
    const getFields = (dataHolder, emission, kind, prefix = '') => {
      return dataHolder[emission][kind].map((d) => ({
        name: `${prefix}${emission}.${kind}-${d.type}`,
        label: getLabel(kind, emission, d.content),
        type: 'number',
        format: getFormat(kind),
      }));
    };
    
    const getObjectValue = (obj, key) => {
      const index = key.lastIndexOf('.');
      const k = key.substring(index + 1);
      return obj[k];
    };
    
    const getArrayValue = (arr, key) => {
      const index = key.lastIndexOf('-');
      const k = key.substring(index + 1);
      const obj = arr.find((d) => d.type === k);
      return obj ? obj.value : 0;
    };
    
    const valueFromObject = (fields, data) => {
      const obj = {};
      fields.forEach((f) => {
        obj[f.name] = getObjectValue(data, f.name);
      });
      return obj;
    };
    
    const valueFromArray = (fields, data) => {
      const obj = {};
      fields.forEach((f) => {
        obj[f.name] = getArrayValue(data, f.name);
      });
      return obj;
    };

    // build dynamic field information from first emission data
    if (!data.constructInfo || data.constructInfo.length < 1 || !data.constructInfo[0].emissions || data.constructInfo[0].emissions.length < 1) {
      return { groupFields: [], tableFields: [], displayData: [] };
    }
    const mainEmissionFields = {};
    mainEmissionFields['general'] = [
      { "name": "project.mainEmission.powderUnit", "label": "単位粉体量(t/m\u00B3)", type: "number", format: getFormatDecimal(3) },
      { "name": "project.mainEmission.powderUsage", "label": "粉体使用量(t)", type: "number", format: getFormatDecimal(3) },
    ];
    ['unit', 'ratio', 'usage', 'emission'].forEach((kind) => {
      mainEmissionFields[kind] = getFields(data.constructInfo[0].emissions[0], 'mainEmission', kind, 'project.');
    });
    const transportEmissionFields = {};
    ['distance', 'emission'].forEach((kind) => {
      transportEmissionFields[kind] = getFields(data.constructInfo[0].emissions[0], 'transportEmission', kind, 'project.');
    });
    const baselineEmissionFields = {};
    ['volume', 'unit', 'usage', 'emission'].forEach((kind) => {
      baselineEmissionFields[kind] = getFields(data.constructInfo[0].emissions[0], 'baselineEmission', kind, 'baseline.');
    });
    const baselineTransportEmissionFields = {};
    ['distance', 'emission'].forEach((kind) => {
      baselineTransportEmissionFields[kind] = getFields(data.constructInfo[0].emissions[0], 'baselineTransportEmission', kind, 'baseline.');
    });
  
    // define table fields
    const tableFields = [];
    tableFields.push(...activityFields);
    tableFields.push(...constructFields);
    tableFields.push(...specFields);
    tableFields.push(...concreteFields);
    ['general', 'unit', 'ratio', 'usage', 'emission'].forEach((kind) => tableFields.push(...mainEmissionFields[kind]));
    ['distance', 'emission'].forEach((kind) => tableFields.push(...transportEmissionFields[kind]));
    tableFields.push(...totalProjectEmissionFields);
    ['volume', 'unit', 'usage', 'emission'].forEach((kind) => tableFields.push(...baselineEmissionFields[kind]));
    ['distance', 'emission'].forEach((kind) => tableFields.push(...baselineTransportEmissionFields[kind]));
    tableFields.push(...totalBaselineEmissionFields);
    tableFields.push(...totalReductionFields);
    tableFields.push(...otherInfoFields);
  
    // define grouping fields
    const createGroupFields = (tableFields, level) => {
      const result = [];
      let lastGroupKey = '';
      tableFields.forEach((d) => {
        const keys = d.name.split('.');
        if (keys.length < level) {
          return;
        }
        const groupKey = keys.slice(0, level).join('.');
        if (d.name === groupKey) {
          result.push({ name: groupKey, label: d.label, canCollapse: false, initialCollapse: false, width: d.width });
        } else if (lastGroupKey !== groupKey) {
          result.push({ name: groupKey, ...groupDictionary[groupKey] });
        }
        lastGroupKey = groupKey;
      });
      return result;
    };
  
    const groupFields = [
      createGroupFields(tableFields, 1),
      createGroupFields(tableFields, 2)
    ];
  
    // convert to display data
    const displayData = [];
    data.constructInfo.forEach((c, i) => {
      c.emissions.forEach((e, j) => {
        const item = {};
        item.activityNumber = i + 1;
        Object.assign(item, valueFromObject(specFields, e));
        Object.assign(item, valueFromObject(constructFields, c));
        Object.assign(item, valueFromObject(concreteFields, e.concreteInfo));
        Object.assign(item, valueFromObject(mainEmissionFields['general'], e.mainEmission));
        Object.assign(item, ['unit', 'ratio', 'usage', 'emission'].reduce((obj, kind) => Object.assign(obj, valueFromArray(mainEmissionFields[kind], e.mainEmission[kind])), {}));
        Object.assign(item, ['distance', 'emission'].reduce((obj, kind) => Object.assign(obj, valueFromArray(transportEmissionFields[kind], e.transportEmission[kind])), {}));
        Object.assign(item, valueFromObject(totalProjectEmissionFields, e));
        Object.assign(item, ['volume', 'unit', 'usage', 'emission'].reduce((obj, kind) => Object.assign(obj, valueFromArray(baselineEmissionFields[kind], e.baselineEmission[kind])), {}));
        Object.assign(item, ['distance', 'emission'].reduce((obj, kind) => Object.assign(obj, valueFromArray(baselineTransportEmissionFields[kind], e.baselineTransportEmission[kind])), {}));
        Object.assign(item, valueFromObject(totalBaselineEmissionFields, e));
        Object.assign(item, valueFromObject(totalReductionFields, e));
        Object.assign(item, valueFromObject(otherInfoFields, e.otherInfo));
        // set background color if data is invalid
        item._sx = item.totalProjectEmission === 0 || item.totalBaselineEmission === 0 ? { background: '#DFEFFF' } : null;
        displayData.push(item);
      });
    });
  
    return { groupFields, tableFields, displayData, data };
  };

  useEffect(() => {
    const paramsStr = sessionStorage.getItem('JCreditReportSubmitData');
    if (!paramsStr) {
      navigate('../');
    }
    const params = JSON.parse(paramsStr);
    const query = async () => {
      try {
        app.setIsLoading(true);
        const res = await app.http().post('/report/jcredit', params);
        // if (res.constructInfo.length < 10) {
        //   res.constructInfo = [...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo, ...res.constructInfo];
        //   res.constructInfo = [...res.constructInfo, ...res.constructInfo];
        // }
        const result = toDisplayData(res);
        setData(result.data);
        setGroupFields(result.groupFields);
        setTableFields(result.tableFields);
        setDisplayData(result.displayData);
      } catch (err) {
        app.handleHttpError(err);
      } finally {
        app.setIsLoading(false);
      }
    };
    query();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDecimal = getFormatDecimal(3);

  const renderPanel = (label, value) => (
    <Box sx={{ flex: 1, textAlign: 'center', maxWidth: '33.3%' }}>
      <div style={{color: '#666666', borderBottom: 'solid #7697A9 1px'}}>{label}</div>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{value}</span>
        <div style={{ fontSize: '0.9rem', position: 'absolute', bottom: 0, right: -50 }}>tCO2</div>
      </div>
    </Box>
  );

  return (
    <div className={classes.root}>
      <AppPrimaryArea title="J-クレジット申請レポート" breadcrumbs={breadcrumbs} />
      <Container component="main" maxWidth={false} sx={{ py: 1, maxWidth: '90vw' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px' }}>
            <Container component="main" disableGutters={true} maxWidth={false} sx={{ mt: 1, minWidth: 600, maxWidth: 900 }}>
              <AppAccordion title={data && data.managementInfo ? data.managementInfo.name : ''} border={true}>
                <Box sx={{ px: 2, pb: 2 }}>
                  <JCreditReportBasicInfo data={data} />
                </Box>
              </AppAccordion>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                {renderPanel('プロジェクト排出量合計', formatDecimal(data ? data.totalEmission : 0))}
                {renderPanel('ベースライン排出量合計', formatDecimal(data ? data.totalBaselineEmission : 0))}
                {renderPanel('排出削減量', formatDecimal(data ? data.totalReduction : 0))}
              </Box>
            </Container>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
          <Box sx={{ marginTop: 'auto', marginBottom: '8px', minWidth: 175 }}>
            <JCreditReportDownload groupFields={groupFields} tableFields={tableFields} data={data} displayData={displayData} />
          </Box>
        </Box>
        <AppDataTable groupFields={groupFields} tableFields={tableFields} data={displayData} maxHeight="calc(100vh - 330px)" />
      </Container>
    </div>
  );
};

export default JCreditReportViewPage;
