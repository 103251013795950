import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import useAppContext, { AppContextProvider } from './common/hooks/useAppContext';
import LoadingDialog from './common/components/LoadingDialog';
import MessageDialog from './common/components/MessageDialog';
import ConfirmDialog from './common/components/ConfirmDialog';
import LoginPage from './login/LoginPage';
import SetupMFAPage from './login/SetupMFAPage';
import VerifyCodePage from './login/VerifyCodePage';
import ConcretePlantPage from './concrete-plant/ConcretePlantPage';
import ConstructorUserPage from './constructor-user/ConstructorUserPage';
import ConstructorAdminPage from './constructor-admin/ConstructorAdminPage';
import PlatformAdminPage from './platform-admin/PlatformAdminPage';
import MasterPage from './master/MasterPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5B6770',
      light: '#C1C6C8'
    },
    secondary: {
      main: '#00ACFF',
      light: '#DFEFFF'
    },
    info: {
      main: '#387FB5'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 1000,
      lg: 1350,
      xl: 1650,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: 80
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        }
      }
    },
    // MuiAccordionSummary: {
    //   styleOverrides: {
    //     root: {
    //       borderTop: 'none',
    //       borderRight: 'solid rgba(0, 0, 0, 0.12) 1px',
    //       borderBottom: 'solid rgba(0, 0, 0, 0.12) 1px',
    //       borderLeft: 'solid rgba(0, 0, 0, 0.12) 1px',
    //       '&.Mui-expanded': {
    //         borderTop: 'solid rgba(0, 0, 0, 0.12) 1px',
    //       }
    //     },
    //   },
    // },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#ffffff'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 150
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: "#DFEFFF"
          },
          '&.Mui-selected': {
            background: "#DFEFFF"
          }
        }
      }
    }
  },
});

const routeByRoles = [
  { role: 'Master', path: '/master', component: <MasterPage /> },
  { role: 'ConcreteMaker', path: '/concrete-plant', component: <ConcretePlantPage /> },
  { role: 'ConstructorUser', path: '/constructor-user', component: <ConstructorUserPage /> },
  { role: 'ConstructorAdmin', path: '/constructor-admin', component: <ConstructorAdminPage /> },
  { role: 'PlatformAdmin', path: '/platform-admin', component: <PlatformAdminPage /> },
  { role: 'DataEntry', path: '/concrete-plant', component: <ConcretePlantPage /> },
];

const Router = () => {
  const app = useAppContext();
  const { loginInfo, isLoading, dialog, onCloseDialog } = app;
  const location = useLocation();

  useEffect(() => {
    if (loginInfo == null) {
      if (location.pathname !== '/' && location.pathname !== '/login' && location.pathname !== '/setup-mfa' && location.pathname !== '/verify-code') {
        app.setPathAfterLogin(location.pathname);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let route = null;
  if (loginInfo) {
    route = routeByRoles.find((r) => r.role === loginInfo.user.role);
  }

  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/setup-mfa" element={<SetupMFAPage />} />
        <Route path="/verify-code" element={<VerifyCodePage />} />
        {!loginInfo && <Route path="*" element={<LoginPage />} />}
        {route && <Route path={`${route.path}/*`} element={route.component} />}
        {route && <Route path='/' element={<Navigate replace to={route.path} />} />}
      </Routes>
      <LoadingDialog open={isLoading} />
      <MessageDialog open={dialog.type === 'Message'} title={dialog.title} body={dialog.body} onClose={onCloseDialog} foot={dialog.foot} />
      <ConfirmDialog open={dialog.type === 'Confirm'} title={dialog.title} body={dialog.body} onClose={onCloseDialog} leftFoot={dialog.leftFoot} rightFoot={dialog.rightFoot} />
    </div>
  );
};

const App = () => (
  <div className="App">
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppContextProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </div>
);

export default App;
